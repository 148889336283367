import queryString from 'query-string'
import {useLocation} from "react-router-dom"
import React from "react";

const H_L = 'homeworkhelp'
const T_L = 'tutorhelp'
const P1 = "Looking for"

const P2 = "Stuck on your"
const P3 = "Need help with"
const P4 = "Stuck on"
const P5 = "Need to prepare for"

const H1_TABLE = [["International Relations tutor", P1, "an International Relations tutor?"],
  ["Environmental Science tutor", P1, "an Environmental Science tutor?"],
  ["Project Management tutor", P1, "a Project Management tutor?"],
  ["Information Theory tutor", P1, "Information Theory tutor?"],
  ["Political Science tutor", P1, "Political Science tutor?"],
  ["help with Macroeconomics", P1, "a Macroeconomics tutor?"],
  ["Macroeconomics tutor", P1, "a Macroeconomics tutor?"],
  ["help with Microeconomics", P1, "a Microeconomics tutor?"],
  ["Microeconomics tutor", P1, "a Microeconomics tutor?"],
  ["research paper tutor", P1, "a research paper tutor?"],
  ["Social Studies tutor", P1, "a Social Studies tutor?"],
  ["Business Studies tutor", P1, "Business Studies tutor?"],
  ["Life Sciences tutor", P1, "a Life Sciences tutor?"],
  ["Music History tutor", P1, "a Music History tutor?"],
  ["Number Theory tutor", P1, "a Number Theory tutor?"],
  ["Public Health tutor", P1, "a Public Health tutor?"],
  ["Anthropology tutor", P1, "an Anthropology tutor?"],
  ["Art History tutor", P1, "an Art History tutor?"],
  ["Game Theory tutor", P1, "a Game Theory tutor?"],
  ["Linguistics tutor", P1, "a Linguistics tutor?"],
  ["Accounting tutor", P1, "an Accounting tutor?"],
  ["help with Accounting", P1, "an Accounting tutor?"],
  ["online Accounting tutoring", P1, "an Accounting tutor?"],
  ["assignment tutor", P1, "an assignment tutor?"],
  ["online assignment tutor", P1, "an assignment tutor?"],
  ["tutor assignment", P1, "an assignment tutor?"],
  ["Immunology tutor", P1, "an Immunology tutor?"],
  ["help with Healthcare", P1, "a Healthcare tutor?"],
  ["Humanities tutor", P1, "a Humanities tutor?"],
  ["Journalism tutor", P1, "a Journalism tutor?"],
  ["Literature tutor", P1, "a Literature tutor?"],
  ["online Literature tutoring", P1, "a Literature tutor?"],
  ["help with Philosophy", P1, "a Philosophy tutor?"],
  ["Philosophy tutor", P1, "a Philosophy tutor?"],
  ["Physiology tutor", P1, "a Physiology tutor?"],
  ["online Psychology tutoring", P1, "a Psychology tutor?"],
  ["Psychology tutor", P1, "a Psychology tutor?"],
  ["help with Statistics", P1, "a Statistics tutor?"],
  ["online Statistics tutoring", P1, "a Statistics tutor?"],
  ["Probability and Statistics tutor", P1, "a Statistics tutor?"],
  ["Statistics tutor", P1, "a Statistics tutor?"],
  ["university tutor", P1, "a university tutor?"],
  ["Astronomy tutor", P1, "an Astronomy tutor?"],
  ["Economics tutor", P1, "an Economics tutor?"],
  ["help with Economics", P1, "an Economics tutor?"],
  ["online Economics tutoring", P1, "an Economics tutor?"],
  ["Education tutor", P1, "an Education tutor?"],
  ["online Education tutoring", P1, "an Education tutor?"],
  ["Chemistry tutor", P1, "a Chemistry tutor?"],
  ["help with Chemistry", P1, "a Chemistry tutor?"],
  ["online Chemistry tutoring", P1, "a Chemistry tutor?"],
  ["Geography tutor", P1, "a Geography tutor?"],
  ["online Geography tutoring", P1, "a Geography tutor?"],
  ["help with Marketing", P1, "a Marketing tutor?"],
  ["Marketing tutor", P1, "a Marketing tutor?"],
  ["online Marketing tutoring", P1, "a Marketing tutor?"],
  ["Nutrition tutor", P1, "a Nutrition tutor?"],
  ["Sociology tutor", P1, "a Sociology tutor?"],
  ["Sociology tutor online", P1, "a Sociology tutor?"],
  ["Business tutor", P1, "a Business tutor?"],
  ["Calculus tutor", P1, "a Calculus tutor?"],
  ["online Calculus tutoring", P1, "a Calculus tutor?"],
  ["Geometry tutor", P1, "a Geometry tutor?"],
  ["online Geometry tutoring", P1, "a Geometry tutor?"],
  ["homework help tutor", P1, "a homework tutor?"],
  ["homework tutor", P1, "a homework tutor?"],
  ["Religion tutor", P1, "a Religion tutor?"],
  ["research tutor", P1, "a research tutor?"],
  ["Theology tutor", P1, "a Theology tutor?"],
  ["Algebra tutor", P1, "an Algebra tutor?"],
  ["online Algebra tutoring", P1, "an Algebra tutor?"],
  ["Ecology tutor", P1, "an Ecology tutor?"],
  ["English tuition online", P1, "an English tutor?"],
  ["English tutor", P1, "an English tutor?"],
  ["help with English", P1, "an English tutor?"],
  ["online English tutoring", P1, "an English tutor?"],
  ["Biology tutor", P1, "a Biology tutor?"],
  ["help with Biology", P1, "a Biology tutor?"],
  ["online Biology tutoring", P1, "a Biology tutor?"],
  ["college tutoring service", P1, "a college tutor?"],
  ["college tutors", P1, "a college tutor?"],
  ["online college tutoring", P1, "a college tutor?"],
  ["Finance tutor", P1, "a Finance tutor?"],
  ["help with Finance", P1, "a Finance tutor?"],
  ["online Finance tutoring", P1, "a Finance tutor?"],
  ["Geology tutor", P1, "a Geology tutor?"],
  ["History tutor", P1, "a History tutor?"],
  ["online History tutoring", P1, "a History tutor?"],
  ["US History tutor", P1, "a History tutor?"],
  ["World History tutor", P1, "a History tutor?"],
  ["Nursing tutor", P1, "a Nursing tutor?"],
  ["Nursing tutoring service", P1, "a Nursing tutor?"],
  ["help with Physics", P1, "a Physics tutor?"],
  ["online Physics tutoring", P1, "a Physics tutor?"],
  ["Physics tutor", P1, "a Physics tutor?"],
  ["help with Science", P1, "a Science tutor?"],
  ["online Science tutoring", P1, "a Science tutor?"],
  ["Science tutor", P1, "a Science tutor?"],
  ["help with Academic Writing", P1, "a writing tutor?"],
  ["writing tutor", P1, "a writing tutor?"],
  ["writing tutors for students", P1, "a writing tutor?"],
  ["Creative Writing tutor", P1, "a writing tutor?"],
  ["help with Academic Writing", P1, "a writing tutor?"],
  ["Ethics tutor", P1, "an Ethics tutor?"],
  ["essay tutor", P1, "an essay tutor?"],
  ["Excel tutor", P1, "an Excel tutor?"],
  ["help with Logic", P1, "a Logic tutor?"],
  ["Logic tutor", P1, "a Logic tutor?"],
  ["Math tutor", P1, "a Math tutor?"],
  ["Maths tutor", P1, "a Math tutor?"],
  ["online Math tutoring", P1, "a Math tutor?"],
  ["online Maths tutoring", P1, "a Math tutor?"],
  ["Art tutor", P1, "an Art tutor?"],
  ["Law tutor", P1, "a Law tutor?"],
  ["academic support", P1, "a tutor?"],
  ["affordable tutoring", P1, "a tutor?"],
  ["best online tutoring", P1, "a tutor?"],
  ["best tutors", P1, "a tutor?"],
  ["cheap tutors", P1, "a tutor?"],
  ["find me a tutor", P1, "a tutor?"],
  ["find tutors", P1, "a tutor?"],
  ["find tutors online", P1, "a tutor?"],
  ["get a tutor", P1, "a tutor?"],
  ["hire a tutor", P1, "a tutor?"],
  ["how to find a tutor", P1, "a tutor?"],
  ["how to get a tutor", P1, "a tutor?"],
  ["instant tutoring", P1, "a tutor?"],
  ["need a tutor", P1, "a tutor?"],
  ["need an online tutor", P1, "a tutor?"],
  ["need online tutor", P1, "a tutor?"],
  ["on demand tutoring", P1, "a tutor?"],
  ["online tutor help", P1, "a tutor?"],
  ["online tutor search", P1, "a tutor?"],
  ["online tutor USA", P1, "a tutor?"],
  ["online tutoring", P1, "a tutor?"],
  ["online tutoring service", P1, "a tutor?"],
  ["online tutoring website", P1, "a tutor?"],
  ["remote tutoring", P1, "a tutor?"],
  ["student to student tutoring", P1, "a tutor?"],
  ["student tutor", P1, "a tutor?"],
  ["students tutoring students", P1, "a tutor?"],
  ["the online tutor", P1, "a tutor?"],
  ["tutor help", P1, "a tutor?"],
  ["tutor website", P1, "a tutor?"],
  ["tutoring in", P1, "a tutor?"],
  ["tutoring service", P1, "a tutor?"],
  ["tutoring students online", P1, "a tutor?"],
  ["tutoring website", P1, "a tutor?"],
  ["virtual tutor", P1, "a tutor?"],
  ["virtual tutor online", P1, "a tutor?"],
  ["where to get a tutor", P1, "a tutor?"],
  ["college homework help", P2, "college homework?"],
  ["do my English homework", P2, "English homework?"],
  ["Finance homework help", P2, "Finance homework?"],
  ["do my Excel homework", P2, "Excel homework?"],
  ["do my homework", P2, "homework task?"],
  ["do my homework for me", P2, "homework task?"],
  ["do my online homework", P2, "homework task?"],
  ["someone to do my homework", P2, "homework task?"],
  ["we do your homework", P2, "homework task?"],
  ["help with homework", P2, "homework task?"],
  ["homework assignment help", P2, "homework task?"],
  ["homework for me", P2, "homework task?"],
  ["homework help", P2, "homework task?"],
  ["homework service", P2, "homework task?"],
  ["pay for homework", P2, "homework task?"],
  ["pay to do homework", P2, "homework task?"],
  ["pay to do my homework", P2, "homework task?"],
  ["do my Math homework for me", P2, "Math homework?"],
  ["writing help", P2, "writing task?"],
  ["do my assignment", P2, "assignment?"],
  ["powerpoint assignment", P4, "a PowerPoint assignment?"],
  ["take my online exam", P3, "your test?"],
  ["do my online test for me", P3, "your test?"],
  ["online test for me", P3, "your test?"],
  ["online test takers", P3, "your test?"],
  ["take my online test", P3, "your test?"],
  ["accounting homework doer", P2, "Accounting homework?"],
  ["accounting homework help", P2, "Accounting homework?"],
  ["pay for accounting homework", P2, "Accounting homework?"],
  ["pay to do accounting homework", P2, "Accounting homework?"],
  ["do my Statistics homework", P2, "Statistics homework?"],
  ["help with Statistics homework", P2, "Statistics homework?"],
  ["pay for statistics homework", P2, "Statistics homework?"],
  ["do my Chemistry homework", P2, "Chemistry homework?"],
  ["Economics homework", P2, "Economics homework?"],
  ["Economics homework help", P2, "Economics homework?"],
  ["do my Sociology homework", P2, "Sociology homework?"],
  ["write assignment", P2, "writing assignment?"],
  ["hire someone to take exam", P3, "your test?"],
  ["hire someone to take my exam", P3, "your test?"],
  ["pay someone to take my exam", P3, "your test?"],
  ["take my exam", P3, "your test?"],
  ["take my test", P3, "your test?"],
  ["take my test for me", P3, "your test?"],
  ["help with Calculus", P2, "Calculus homework?"],
  ["Geometry homework help", P2, "Geometry homework?"],
  ["help with Geometry", P2, "Geometry homework?"],
  ["algebra homework help", P2, "Algebra homework?"],
  ["do my algebra homework", P2, "Algebra homework?"],
  ["college homework help", P2, "college homework?"],
  ["Finance homework help", P2, "Finance homework?"],
  ["do my History homework", P2, "History homework?"],
  ["history homework help", P2, "History homework?"],
  ["do my Math assignment", P2, "Math assignment?"],
  ["Math assignment help", P2, "Math assignment?"],
  ["do my Excel homework", P2, "Excel homework?"],
  ["Excel homework", P2, "Excel homework?"],
  ["research paper pay", P2, "research paper?"],
  ["24 hours homework help", P2, "homework task?"],
  ["do homework", P2, "homework task?"],
  ["do homework for me", P2, "homework task?"],
  ["do my homework for me", P2, "homework task?"],
  ["do my homework for money", P2, "homework task?"],
  ["do my hw", P2, "homework task?"],
  ["do my online homework", P2, "homework task?"],
  ["do your homework", P2, "homework task?"],
  ["do your homework for you", P2, "homework task?"],
  ["doing homework", P2, "homework task?"],
  ["get homework help", P2, "homework task?"],
  ["help in homework", P2, "homework task?"],
  ["help me with homework", P2, "homework task?"],
  ["help with homework", P2, "homework task?"],
  ["helping with homework", P2, "homework task?"],
  ["hire someone to do homework", P2, "homework task?"],
  ["hire to do homework", P2, "homework task?"],
  ["homework doer", P2, "homework task?"],
  ["homework for me", P2, "homework task?"],
  ["homework help", P2, "homework task?"],
  ["homework help online", P2, "homework task?"],
  ["homework helpers", P2, "homework task?"],
  ["homework to get done", P2, "homework task?"],
  ["homework website", P2, "homework task?"],
  ["need someone to do my homework", P2, "homework task?"],
  ["online homework help", P2, "homework task?"],
  ["paid homework", P2, "homework task?"],
  ["pay for homework help", P2, "homework task?"],
  ["pay people to do your homework", P2, "homework task?"],
  ["pay someone to do homework", P2, "homework task?"],
  ["pay to do homework", P2, "homework task?"],
  ["pay to do my homework", P2, "homework task?"],
  ["pay to get homework done", P2, "homework task?"],
  ["pay to have homework done", P2, "homework task?"],
  ["people do your homework", P2, "homework task?"],
  ["solve homework", P2, "homework task?"],
  ["someone to do my homework", P2, "homework task?"],
  ["someone to do your homework", P2, "homework task?"],
  ["do my math homework", P2, "Math homework?"],
  ["do my math homework for me", P2, "Math homework?"],
  ["help me with my math homework", P2, "Math homework?"],
  ["help with Math", P2, "Math homework?"],
  ["help with Maths", P2, "Math homework?"],
  ["math homework doer", P2, "Math homework?"],
  ["Math homework help", P2, "Math homework?"],
  ["Math homework helper", P2, "Math homework?"],
  ["pay someone to do math homework", P2, "Math homework?"],
  ["pay someone to do my math homework", P2, "Math homework?"],
  ["pay to do Math homework", P2, "Math homework?"],
  ["pay to do my math homework", P2, "Math homework?"],
  ["solve my Math homework", P2, "Math homework?"],
  ["someone to do my math homework", P2, "Math homework?"],
  ["do my presentation for me", P2, "presentation?"],
  ["presentation help", P2, "presentation?"],
  ["writing help", P2, "writing task?"],
  ["assignment done for you", P2, "assignment?"],
  ["assignment for me", P2, "assignment?"],
  ["assignment help", P2, "assignment?"],
  ["assignment helper", P2, "assignment?"],
  ["do my assignment", P2, "assignment?"],
  ["do my assignments", P2, "assignment?"],
  ["pay assignment", P2, "assignment?"],
  ["pay someone to do my assignment", P2, "assignment?"],
  ["pay to do my assignment", P2, "assignment?"],
  ["someone do assignment", P2, "assignment?"],
  ["do my project", P2, "project?"],
  ["do your project", P2, "project?"],
  ["project help", P2, "project?"],
  ["Calculus help", P1, "a Calculus tutor?"],
  ["Differential Calculus help", P1, "a Calculus tutor?"],
  ["Integral Calculus help", P1, "a Calculus tutor?"],
  ["Algorithms help", P1, "a Math tutor?"],
  ["Differential Equations tutor", P1, "a Math tutor?"],
  ["Math functions help", P1, "a Math tutor?"],
  ["Math online help", P1, "a Math tutor?"],
  ["Number Theory help", P1, "a Math tutor?"],
  ["online Math help", P1, "a Math tutor?"],
  ["Radicals help", P1, "a Math tutor?"],
  ["Trigonometry tutor", P1, "a Math tutor?"],
  ["online Statistics help", P1, "a Stats tutor?"],
  ["Probability tutor", P1, "a Stats tutor?"],
  ["Statistics help", P1, "a Stats tutor?"],
  ["Stats help online", P1, "a Stats tutor?"],
  ["help with equations", P1, "a tutor?"],
  ["Linear Algebra tutor", P1, "an Algebra tutor?"],
  ["Algebra exam preparation", P5, "Algebra exam?"],
  ["Calculus exam preparation", P5, "Calculus exam?"],
  ["Geometry exam preparation", P5, "Geometry exam?"],
  ["Math exam preparation", P5, "Math exam?"],
  ["Math test preparation", P5, "Math test?"],
  ["Statistics exam preparation", P5, "Stats exam?"],
  ["college Algebra assignment", P2, "Algebra assignment?"],
  ["Algebra homework", P2, "Algebra homework?"],
  ["Algebra problem solving", P2, "Algebra problem?"],
  ["Algebra problems help", P2, "Algebra problem?"],
  ["do my Algebra problem", P2, "Algebra problem?"],
  ["help solving Algebra problems", P2, "Algebra problem?"],
  ["help with Algebra word problems", P2, "Algebra problem?"],
  ["solve my Algebra problem", P2, "Algebra problem?"],
  ["Calculus assignment", P2, "Calculus assignment?"],
  ["Calculus homework help", P2, "Calculus homework?"],
  ["Calculus homework", P2, "Calculus homework?"],
  ["Calculus solver", P2, "Calculus homework?"],
  ["Calculus website", P2, "Calculus homework?"],
  ["do Calculus", P2, "Calculus homework?"],
  ["Business calculus problems", P2, "Calculus problem?"],
  ["Calculus problem solver", P2, "Calculus problem?"],
  ["Calculus problem solving", P2, "Calculus problem?"],
  ["solve my Calculus problem", P2, "Calculus problem?"],
  ["solve my Calculus problems", P2, "Calculus problem?"],
  ["solving Calculus problems", P2, "Calculus problem?"],
  ["Geometry problem help", P2, "Geometry problem?"],
  ["solve my Geometry problem", P2, "Geometry problem?"],
  ["help me solve this equation", P2, "homework?"],
  ["help solving equations", P2, "homework?"],
  ["do my Math assignment for me", P2, "Math assignment?"],
  ["Math assignment helper", P2, "Math assignment?"],
  ["help solving Math equations", P2, "Math homework?"],
  ["help with Math homework", P2, "Math homework?"],
  ["help with Math questions", P2, "Math homework?"],
  ["Math assistance websites", P2, "Math homework?"],
  ["Math essay", P2, "Math homework?"],
  ["Math help websites", P2, "Math homework?"],
  ["Math homework done for you", P2, "Math homework?"],
  ["Math homework service", P2, "Math homework?"],
  ["Math matrix help", P2, "Math homework?"],
  ["Math paper", P2, "Math homework?"],
  ["Math solvers", P2, "Math homework?"],
  ["Math solving website", P2, "Math homework?"],
  ["PreCalculus homework", P2, "Math homework?"],
  ["solve Math question", P2, "Math homework?"],
  ["Math helpers", P2, "Math homework?"],
  ["do my Math problem", P2, "Math problem?"],
  ["help with Math problems", P2, "Math problem?"],
  ["Integral problem solving", P2, "Math problem?"],
  ["solve Math problems", P2, "Math problem?"],
  ["solve my Math problem", P2, "Math problem?"],
  ["do my Math project", P2, "Math project?"],
  ["Statistics assignment", P2, "Stats assignment?"],
  ["do my Stats homework", P2, "Stats homework?"],
  ["solve my Statistics homework", P2, "Stats homework?"],
  ["Statistics Excel", P2, "Stats homework?"],
  ["Statistics homework doer", P2, "Stats homework?"],
  ["Statistics homework help", P2, "Stats homework?"],
  ["Statistics homework", P2, "Stats homework?"],
  ["Statistics problem solving", P2, "Stats problem?"],
  ["Regression project", P2, " Stats project?"],
]

const H1 = (L, pathname, search) => {
  const keyword = queryString.parse(search).utm_term
  const result = keyword && H1_TABLE.find(item => item[0].toLowerCase() === keyword.toLowerCase())
  return ['/homework-help-3', '/tutoring'].indexOf(pathname) > -1 && result && ((result[1].length + result[2].length) <= 35)
    ? [result[1], result[2]]
    : L === T_L
      ? ['Looking for', 'a tutor?']
      : ['Stuck on your', 'homework task?']
}

const faq = {
  title: 'Frequently Asked Questions',
  items: [
    {
      question: 'How does it work?',
      answer: 'Send a brief description of what you need help with or simply take a picture of the task you are stuck on. Tell us how soon you would like to get help from a tutor and how much time you would like your tutor to spend on helping you. Once you make a payment, we’ll find and connect you to your tutor. You’ll be able to chat right away using our instant web messenger.',
    },
    {
      question: 'How does the payment work?',
      answer: 'Tell us how much time you would like your tutor to spend on your task and how soon you would like to get help, then make the payment. All of the payments are processed by the idependent gateway and are 100% secure. Once you make the payment we’ll connect you with your tutor. Tutors always double-check task details and will let you know in case there are any questions. Didn’t use all of the tutoring hours you have paid for? We’ll refund the difference. No questions asked! Need more time? No worries! You’ll always buy more tutoring hours if needed! Don’t forget that you can always ask follow-up questions and chat with your tutor for free for up to 24 hours after you are done with your actual tutoring session.'
    },
    {
      question: 'How to cancel my task request?',
      answer: 'If you want to cancel your task request or request a refund, simply message our Customer Support Team. They are always here to help you! ',
    },
    {
      question: 'Can tutors do my homework?',
      answer: 'We have strict rules regarding tutors being engaged in any kind of dishonest activity which includes infringement of college policies. Thus, a tutor will refuse to do your homework. Instead, s/he can help you find materials, prepare notes, master the topic you are learning, get ready for a test, etc.',
    },
    {
      question: 'What type of tasks can I get help with?',
      answer: 'Tutors can help with any task or topic as long as it doesn’t violate college policies. We have tutors in almost every subject and topic. Simply tell us what you need help with and we’ll find a perfect tutor for your task.',
    },
    {
      question: 'How much does it cost?',
      answer: 'Our tutors charge for the time they spend on working with you on your task. Our prices start at $0.5 for one minute of tutor\'s help',
    },
  ],
}

const footer = {
  addresses: [
    {
      key: 'hk',
      country: 'HK',
      address: <>UNIT 1603, 16TH FLOOR, THE L.<br/>
        PLAZA 367-375 QUEEN'S ROAD<br/>
        CENTRAL SHEUNG WAN<br/>
        HONG KONG</>
    },
/*
    {key: 'us', country: 'US', address: '75 E Santa Clara St, San Jose, CA 95113, United States'},
    {key: 'eu', country: 'EU', address: 'str. Iliensko shose No 2, office 9, 1220 Sofia, Bulgaria'},
*/
  ],
}

const SEO = {
  "/homework-help-3": {
    title: "Nerdy Tutors | Get On-Demand Homework Help from Top Tutors",
    description: "Get Help on Your Task ASAP. Tell Us What You Are Stuck on and Get On-Demand Help from One of Our Top Tutors.",
    keywords: "homework help, do my homework, do my math homework, help with homework, someone to do homework, do my assignment, writing help, helping with homework, algebra homework help, homework help online, take my test, get homework help, do my statistics homework, homework for me, help me with my math homework, do my assignment, writing help, homework assignment help,  help with Math, project help, finance homework help"
  },
  "/tutoring": {
    title: "Nerdy Tutors | Get Help from Top Tutors ASAP",
    description: "Thousands of Tutors Are Always at Your Fingertips. Get Matched With a Perfect Tutor in Minutes!",
    keywords: "online tutoring, writing tutor, tutoring service, university tutor, college tutors, find tutors online, tutor help, student tutor, get a tutor, essay tutor, find me a tutor, homework tutor, psychology tutor, help with math, math tutor, english tutor, help with geometry, chemistry tutor, language tutor, physics tutor, online math tutoring, art tutor, college tutors, accounting tutor, finance tutor, writing tutor, biology tutor"
  },
  "/": {
    title: "Nerdy Tutors | On-Demand Tutoring Platform",
    description: "1000s of tutors are always at your fingertips. Chat directly to tutors in our messenger.",
    keywords: "online tutoring, tutoring help, nerdy tutors, homework tutoring, english tutor, best tutoring website, online tutor, writing tutor, tutor site, tutoring service, math tutor, accounting tutor"
  }
}

const Seo = () => {
  const {pathname, search} = useLocation()
  const L = pathname === "/homework-help-3" ? H_L : T_L
  const h1 = H1(L, pathname, search)
  const seo = SEO[pathname] || SEO[""]
  return {
    [H_L]: {
      ...seo,
      hero: {
        h1: h1,
        title: 'We’ll connect you to one of our <b>3021 top tutors</b> within minutes!',
        subtitle: 'Chat directly with your tutor and get help in over <b>250 subjects</b>.',
        buttonText: 'Get Help Now',
      },
      howItWorks: {
        title: 'Here is how it works',
        items: [
          {
            title: 'Tell us what are you stuck on',
            subtitle: 'Select a subject, share task details and tell us your deadline',
          },
          {
            title: 'Connect with the best-match tutor within minutes',
            subtitle: 'Our AI will connect you to the best available tutor',
          },
          {
            title: 'Get help in any way you like right in the messenger',
            subtitle: 'Chat with your tutor, jump on live tutoring sessions, get written explanations and more',
          },
          {
            title: 'Ask any follow-up questions after your session for FREE',
            subtitle: 'Enjoy direct chat with your tutor for 24 hours after your deadline to clarify anything you need',
          },
        ],
      },
      examples: {
        title: 'Students ask for help with all kinds of tasks',
      },
      payment: {
        imageName: 'price',
      },
      cta: {
        title: '30 seconds is all it takes to get a perfect tutor working on your task!',
        buttonText: 'Get Help Now',
      },
      faq,
      footer,
    },
    [T_L]: {
      ...seo,
      hero: {
        h1: h1,
        title: 'We’ll connect you to one of our <b>3021 top tutors</b> within minutes!',
        subtitle: 'Chat directly with your tutor and get help in over <b>250 subjects</b>.',
        buttonText: 'Get a Tutor',
      },
      howItWorks: {
        title: 'Here is how it works',
        items: [
          {
            title: 'Tell us what you are stuck on',
            subtitle: 'Select a subject and tell us by when you would like to start your tutoring session',
          },
          {
            title: 'Connect with the best-match tutor within minutes',
            subtitle: 'Our AI will connect you to the best available tutor',
          },
          {
            title: 'Get help in any way you like right in the messenger',
            subtitle: 'Chat with your tutor, jump on live tutoring sessions, get written explanations and more',
          },
          {
            title: 'Ask any follow-up questions after your session for FREE',
            subtitle: 'Enjoy direct chat with your tutor for 24 hours after your session to clarify anything you need',
          },
        ],
      },
      examples: {
        title: 'Students ask for help with all types of tasks',
      },
      payment: {
        imageName: 'price',
      },
      cta: {
        title: '30 seconds is all it takes to get a perfect tutor helping you out!',
        buttonText: 'Get a Tutor',
      },
      faq,
      footer,
    },
  }[L]
}

export default Seo
